import { Injectable } from '@angular/core';
import { IApi, ServerPageInput } from '../http-handler/common/contracts/api';
import { Campaign } from '../models/campaign.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import {
  Order,
  OrderTest,
  ProductsArray,
  RedeemLink,
  TremendousProduct,
} from '../models/tremendous.product.model';
import { AppService } from './app.service';
import { serialize } from 'v8';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  campaigns: IApi<Campaign>;

  constructor(http: HttpClient, private _appService: AppService) {
    this.campaigns = new GenericApi<Campaign>('campaigns', http);
  }

  getByInviteLink(inviteLink: string): Promise<Campaign> {
    return this.campaigns.simpleGet({
      path: `invite/link?inviteCode=${inviteLink}`,
    });
  }

  getTremendousProduct(
    category?: string,
    search?: string
  ): Promise<TremendousProduct[]> {
    const url =
      category !== 'All' && category
        ? `products/get?category=${encodeURIComponent(category)}`
        : 'products/get';
    const serverPageInput = new ServerPageInput();
    console.log(search);
    if (search) {
      serverPageInput.query = { search: search };
    }
    return this.campaigns
      .search(serverPageInput, url)
      .then((d) => {
        return d.items as any;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return [];
      });
  }

  createOrder(
    { id: campaignId, remainingAmount: amount }: Campaign,
    products: string[]
  ): Promise<Order> {
    return this.campaigns
      .simpePost(
        {
          campaignId,
          amount: amount,
          email: this._appService.user?.email,
          products,
        },
        'tremendous/order'
      )
      .then((d: { order: Order }) => {
        return d.order;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return null;
      });
  }

  redeemLink(data: any): Promise<RedeemLink> {
    console.log(data);
    return this.campaigns
      .simpePost(
        {
          ...data,
        },
        'create/rewardLink'
      )
      .then((d) => {
        return d;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return null;
      });
  }

  RedeemDonate(model: Campaign, bankDetail?: any): Promise<Campaign> {
    var {
      isAutoEmailToRecipient,
      purchaseCard,
      id,
      payoutType,
      charityAmount,
      comment,
      cardAmount,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      selectedTemplateUrl,
      stripeCharges,
      templateType,
      isAmountProcessed,
      productId,
      giftCardList,
      isDonated,
      cardSendEmailTime,
      sendToRecipientEmail,
    } = model;
    userAmount =
      payoutType === 'account' ? parseFloat(userAmount.toFixed(2)) : 0;
    let body: any = {
      isDonated,
      purchaseCard,
      charityAmount,
      cardAmount,
      comment,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      stripeCharges,
      templateType,
      selectedTemplateUrl,
      isAmountProcessed,
      productId,
      giftCardList,
      cardSendEmailTime,
      sendToRecipientEmail,
      isAutoEmailToRecipient,
      ...{ ...bankDetail },
    };
    return this.campaigns
      .update(id, body, null, `redeem/donate/brandedCurrency/${id}`)
      .then((d) => {
        if (payoutType === 'account') {
          this._appService.snackBarOpen(
            'The payout amount is on its way to your bank account and will arrive within 7 days'
          );
        } else if (payoutType === 'catalogue') {
          this._appService.snackBarOpen(
            'Your catalogue is successfully sent on your email address.'
          );
        }
        return d;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return null;
      });
  }

  getCampaignId(campaign: Campaign) {
    if (!campaign.inviteLink) return '';
    // console.log(link);
    return campaign.inviteLink.split('/').pop();
  }

  removeEmptyGiftCards(items: number[]) {
    return items.filter(
      (item) => item !== 0 && item !== undefined && item !== null
    );
  }

  removeEmptyKeysValues(obj: { [key: string]: any }) {
    return Object.entries(obj)
      .filter(([_, v]) => v !== '' && v !== null && v !== undefined)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
