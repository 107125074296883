export class TremendousProduct {
  id?: string;
  name?: string;
  currency_codes?: string[] = [];
  skus?: ProductAmount[] = [];
  Category?: string;
  disclosure?: string;
  description?: string;
  images?: ProductImage[] = [];
  max?: number;
  min?: number;
  imgUrl?: string;
  image_thumb_url?: string;
  countries?: [{ abbr: string }];
  currency_code?: string;
  image_url?: string;
  items?: Items[];
  max_value?: string;
  min_value?: string;
  product_id?: number;
  redeem_instructions?: string;
  disclaimer?: string;
  terms_and_conditions?: string;
}

export class Order {
  id?: string;
  external_id?: string;
  created_at?: string;
}

export class RedeemLink {
  amount?: number;
  currencyCode?: string;
  campaignId?: number;
  selectedTemplateUrl?: string;
  templateType?: string;
  charityAmount?: number;
  isDonated?: boolean;
}

export class OrderTest {
  customer_email: string;
  product_code: number;
  product_value: number;
}

export class Items {
  denomination_type?: string;
  name?: string;
  value?: number;
  code?: number;
}

export class GiftAmount {
  giftAmount?: number;
}

interface ProductAmount {
  min?: string;
  max?: string;
}

interface ProductImage {
  src?: string;
  type?: string;
}

export class SelectedProductsArray {
  amount?: number;
  name?: string;
  item?: TremendousProduct;
}

export class ProductsArray {
  amount?: number;
  productId?: number;
}
