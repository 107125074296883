import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit,AfterViewInit {
  
  @Input() control:FormControl = new FormControl('');
  @Input() minDate:Date;
  @ViewChild('date',{static:false}) date:MatDatepicker<any>;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.date
  }

  dateChange(d:Date){
    console.log('d',d);
    d = new Date(d);
    console.log(d);
    
    d.setHours(23);
    console.log(d);
    
    d.setMinutes(59);
    console.log(d);
    
    this.control.setValue(d);
    console.log(this.control);
    
    
  }

}
