import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import {
  GiftAmount,
  TremendousProduct,
} from 'src/app/models/tremendous.product.model';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { ContributionService } from 'src/app/services/contributions.service';
import { CatalogueCardComponent } from './catalogue-card/catalogue-card.component';
import { debounce, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-catalogue-product-and-proceed',
  templateUrl: './create-catalogue-product-and-proceed.component.html',
  styleUrls: ['./create-catalogue-product-and-proceed.component.scss'],
})
export class CreateCatalogueProductAndProceedComponent implements OnInit {
  tremendousProducts: { list: TremendousProduct[]; isLoading: boolean } = {
    list: [],
    isLoading: false,
  };
  isFullProductsListLoading: boolean = false;
  searchVal: '';
  category: '';
  fullTremendousList: TremendousProduct[];
  isLoading: boolean = false;
  selectedProduct: TremendousProduct;
  selectedProducts = [];
  isPayout: boolean = false;
  public get campaign() {
    return this.data.campaign;
  }
  private searchSubject: Subject<string> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<CreateCatalogueProductAndProceedComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: GiftAmount;
      campaign: Campaign;
      viewOnly: boolean;
      multiple: boolean;
      productId: any;
      remainingAmount: number;
      isSingleToProceed: boolean;
      charityAmount: number;
      donationType: boolean;
    },
    private _appService: AppService,
    private _contributionService: ContributionService,
    private _campaignService: CampaignService,
    private _dialog: MatDialog
  ) {
    console.log(data);
    dialogRef.addPanelClass('create-catalogue-product-and-proceed');
    dialogRef.disableClose = true;
    const campaign = this.campaign;
    var collectedAmount = this.campaign.collectedAmount;
    var giftAmount = this.campaign.giftAmount;
    var cardAmount = this.campaign.cardAmount;
    var remainingAmount = this.campaign.remainingAmount;
    var charityAmount = collectedAmount - Number(giftAmount);
    charityAmount = charityAmount - cardAmount || 0;
    this.isPayout = data?.productId?.isPayout;
    remainingAmount =
      collectedAmount - (+cardAmount || 0) - (+charityAmount || 0);
    if (this.campaign.wantToDonate) {
      // charityAmount = remainingAmount - this.data.campaign?.giftAmount;
    } else {
      charityAmount = 0;
      this.campaign.giftAmount = remainingAmount;
    }
    data.campaign = {
      ...campaign,
      charityAmount,
      stripeCharges: 0,
      userAmount: 0,
      remainingAmount,
      isAmountProcessed: true,
    };
    this.fetchTremendousProducts();

    this.searchSubject.pipe(debounceTime(400)).subscribe(() => {
      this.search(this.searchVal);
    });
  }

  ngOnInit() {}

  onSearchInput() {
    this.searchSubject.next(this.searchVal);
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
  }

  selectItem(item: TremendousProduct) {
    if (this.data?.viewOnly) return;
    this.selectedProduct = { ...item };
    this.selectedProducts = [item, ...this.selectedProducts];
  }

  catalogueCard(item: TremendousProduct, view: boolean) {
    const dialogRef = this._dialog.open(CatalogueCardComponent, {
      maxHeight: '80vh',
      maxWidth: '800px',
      width: '800px',
      height: 'auto',
      data: { data: item, view, multiple: this.data.multiple },
    });
    dialogRef.componentInstance.itemSelected.subscribe((d) => {
      this.selectItem(d.data);
      if (d?.isSelected) {
        this.dialogRef.close({
          multiple: true,
          data: this.selectedProduct,
        });
      }
    });
  }

  async search(value: string) {
    this.tremendousProducts.isLoading = true;
    this.isFullProductsListLoading = true;
    const searchVal = value.toLowerCase();
    if (!searchVal) {
      this.tremendousProducts.isLoading = false;
      this.isFullProductsListLoading = false;
      return (this.fullTremendousList = this.tremendousProducts.list);
    }
    this.tremendousProducts.isLoading = true;
    this.isFullProductsListLoading = true;
    this.fullTremendousList = this.tremendousProducts?.list?.filter((list) => {
      const name = list?.name?.toLowerCase();
      return name?.includes(searchVal);
    });
    console.log(this.fullTremendousList);
    this.tremendousProducts.isLoading = false;
    this.isFullProductsListLoading = false;
  }

  async fetchTremendousProducts(search?: string) {
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list =
      await this._campaignService.getTremendousProduct(this.category, search);
    if (this.data?.data?.giftAmount) {
      this.fullTremendousList = this.tremendousProducts.list.filter(
        (item) =>
          parseFloat(item?.min_value) <= Number(this.data?.data?.giftAmount) &&
          parseFloat(item?.max_value) >= Number(this.data?.data?.giftAmount) &&
          item.product_id !== 101582 &&
          item?.items?.filter((card) => card.denomination_type !== 'fixed')
            .length > 0
      );

      this.tremendousProducts.isLoading = false;
      return;
    }
    if (this.data?.productId?.giftAmount) {
      this.fullTremendousList = this.tremendousProducts.list.filter(
        (item) =>
          parseFloat(item?.min_value) <=
            Number(this.data?.productId?.giftAmount) &&
          parseFloat(item?.max_value) >=
            Number(this.data?.productId?.giftAmount) &&
          // this.productsIds.includes(item.product_id) &&
          item.product_id !== 101582 &&
          item?.items?.filter((card) => card.denomination_type !== 'fixed')
            .length > 0
      );

      this.tremendousProducts.isLoading = false;
      return;
    }
    if (this.data?.charityAmount && this.data.isSingleToProceed) {
      this.fullTremendousList = this.tremendousProducts.list.filter(
        (item) =>
          parseFloat(item?.min_value) <= Number(this.data?.remainingAmount) &&
          parseFloat(item?.max_value) >= Number(this.data?.remainingAmount) &&
          // this.productsIds.includes(item.product_id) &&
          item.product_id !== 101582 &&
          item?.items?.filter((card) => card.denomination_type !== 'fixed')
            .length > 0
      );

      this.tremendousProducts.isLoading = false;
      return;
    }
    if (this.data?.productId?.data === 101582) {
      this.fullTremendousList = this.tremendousProducts.list.filter(
        (item) => item?.product_id === 101582
      );

      console.log(this.fullTremendousList);
      this.tremendousProducts.isLoading = false;
      return;
    } else {
      this.fullTremendousList = this.tremendousProducts.list.filter(
        (item) =>
          item?.items?.filter((card) => card.denomination_type !== 'fixed')
            .length > 0 && item.product_id !== 101582
      );
      console.log(this.fullTremendousList);
      this.tremendousProducts.isLoading = false;
    }
  }

  // async fetchTremendousProducts() {
  //   this.tremendousProducts.isLoading = true;

  //   // Fetch the tremendous products list
  //   this.tremendousProducts.list = await this._campaignService.getTremendousProduct(this.category);

  //   // Helper function to filter products by gift amount
  //   const filterByGiftAmount = (amount: number) =>
  //     this.tremendousProducts.list.filter(item => (
  //       parseFloat(item?.min_value) <= amount &&
  //       parseFloat(item?.max_value) >= amount &&
  //       // this.productsIds.includes(item.product_id) &&
  //       item?.items?.some(card => card.denomination_type !== 'fixed')
  //     ));

  //   // Conditions for different types of gift amounts and charity
  //   if (this.data?.data?.giftAmount) {
  //     this.fullTremendousList = filterByGiftAmount(Number(this.data.data.giftAmount));
  //   } else if (this.data?.productId?.giftAmount) {
  //     this.fullTremendousList = filterByGiftAmount(Number(this.data.productId.giftAmount));
  //   } else if (this.data?.charityAmount && this.data.isSingleToProceed) {
  //     this.fullTremendousList = filterByGiftAmount(Number(this.data.remainingAmount));
  //   } else if (this.data?.productId?.data === 101582) {
  //     this.fullTremendousList = this.tremendousProducts.list.filter(item => item?.product_id === 101582);
  //     console.log(this.fullTremendousList);
  //   } else {
  //     // Default filter for products without fixed denomination
  //     this.fullTremendousList = this.tremendousProducts.list.filter(item => (
  //       item?.items?.filter(card => card.denomination_type !== 'fixed').length > 0
  //       // && this.productsIds.includes(item.product_id)
  //     ));
  //     console.log(this.fullTremendousList);
  //   }

  //   this.tremendousProducts.isLoading = false;
  // }

  checkIsInMaxMin(p: TremendousProduct) {
    const min = Math.min(...p.skus.map((i) => parseFloat(i.min)));
    const max = Math.max(...p.skus.map((i) => parseFloat(i.max)));
    return !(
      min <= this.campaign.remainingAmount &&
      this.campaign.remainingAmount <= max
    );
  }

  async create() {
    this.isLoading = true;
    let body = { ...this.campaign };
    if (this.data.campaign?.templateType == 'freeCard') {
      body.cardAmount = 0;
      body.remainingAmount = this.campaign.collectedAmount;
    }
    const order = await this._campaignService.createOrder(body, [
      this.selectedProduct.id,
    ]);
    if (!order) {
      return (this.isLoading = false);
    }
    this.done();
  }

  async done() {
    this.isLoading = true;
    let body = {
      id: this.campaign.id,
      purchaseCard: true,
      charityAmount: this.campaign.charityAmount,
      cardAmount: this.campaign.cardAmount,
      comment: this.campaign.comment,
      userAmount: this.campaign.userAmount,
      cardRecipientEmail: this.campaign.cardRecipientEmail,
      campaignCurrency: this.campaign.campaignCurrency,
      charityId: this.campaign.charityId,
      giftAmount: parseInt(this.data.productId.giftAmount),
      automaticCardEmail: this.campaign.automaticCardEmail,
      cardEmailTime: this.campaign.cardEmailTime,
      receipientEmail: this.campaign.cardRecipientEmail,
      stripeCharges: this.campaign.stripeCharges,
      templateType: this.campaign.templateType,
      selectedTemplateUrl: this.campaign.selectedTemplateUrl,
      isAmountProcessed: this.campaign.isAmountProcessed,
      productId: this.selectedProduct.items[0].code,
      donatedAmount: this.data.remainingAmount,
      isDonated: this.data.productId.donationType,
      sendToRecipientEmail: this.data.campaign.automaticCardEmail,
      isAutoEmailToRecipient : this.data.campaign.isAutoEmailToRecipient
    };
    if (this.campaign.templateType == 'freeCard') {
      body.cardAmount = 0;
    }
    console.log(body);
    const payload = this._campaignService.removeEmptyKeysValues(body)
    const data = await this._campaignService.RedeemDonate(payload);
    // const data = '';
    if (!data) {
      return (this.isLoading = false);
    }

    this.isLoading = false;
    this.dialogRef.close({ data, isSingleToProceed: true });
  }

  back() {
    this.dialogRef.close({
      multiple: false,
      data: this.selectedProduct,
    });
  }

  public productsIds = [
    3966, 3982, 3986, 4037, 92641, 4039, 39929, 41678, 4074, 4166, 4168, 4175,
    92772, 4358, 84017, 4264, 4336, 4338, 46132, 4368, 62964, 4398, 4400, 4410,
    4412, 4535, 84019, 4651, 4747, 4771, 109790, 4846, 11563, 86158, 4894, 4912,
    5048, 5100, 11545, 5281, 5289, 39955, 65642, 39965, 5578, 5342, 5344,
    109111, 11572, 11574, 11576, 65644, 5594, 5674, 5692, 5700, 66521, 5722,
    110522, 5744, 5757, 5854, 5870, 5874, 116405, 5898, 109050, 11586, 84523,
    6085, 45257, 6160, 6196, 114425, 6230, 6238, 6242, 6316, 6326, 40317, 6353,
    67126, 40446, 6441, 72525, 6480, 64926, 6573, 6577, 116661, 40383, 65694,
    65692, 86916, 39401, 6725, 86384, 78229, 113900, 49440, 66670, 6246, 6899,
    96413, 99736, 47302, 40404, 42055, 6945, 40406, 116604, 6969, 74465, 7046,
    49487, 40505, 39991, 40429, 89645, 65972, 113890, 116474, 7087, 7089, 92629,
    7097, 9781, 21236, 64609, 7155, 7157, 7159, 7161, 21238, 78231, 78233,
    78235, 108086, 7186, 7188,
  ];
}
