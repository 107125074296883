import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Category } from 'src/app/models/category.model';
import { CategoriesService } from 'src/app/services/categories.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GiftSuggestionService } from 'src/app/services/gift-suggestion.service';
import { MatRadioButton } from '@angular/material/radio';
import { ImageCropperComponent } from 'src/app/modules/shareModule/components/image-cropper/image-cropper.component';
import { FileUploaderComponent } from 'src/app/modules/shareModule/components/file-uploader/file-uploader.component';
import {
  CurrenciesService,
  Currency,
} from 'src/app/services/currencies.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CreateCatalogueProductAndProceedComponent } from '../create-catalogue-product-and-proceed/create-catalogue-product-and-proceed.component';
import { NotLoginPopupComponent } from './notLoginPopup/notLoginPopup.component';

@Component({
  selector: 'app-create-collect-pot',
  templateUrl: './create-collect-pot.component.html',
  styleUrls: ['./create-collect-pot.component.scss'],
})
export class CreateCollectPotComponent
  implements OnInit, OnChanges, AfterViewInit
{
  ageRangeOptions = [
    { label: '1 - 6', value: '6' },
    { label: '7 - 16', value: '16' },
    { label: '17 - 25', value: '25' },
    { label: '26 - 35', value: '35' },
    { label: '36 - 50', value: '50' },
    { label: '50 - 75', value: '75' },
  ];

  @Input() form: FormGroup;
  @Input() id: number;
  @Output() idChange: EventEmitter<any> = new EventEmitter();
  @Input() campaign: Campaign;
  @Output() campaignChange: EventEmitter<Campaign> = new EventEmitter();
  @Output() next: EventEmitter<number> = new EventEmitter();
  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('suggestionFromContributors')
  suggestionFromContributors: MatRadioButton;
  @ViewChild('suggestionPoll') suggestionPoll: MatRadioButton;
  @ViewChild('decided') decided: MatRadioButton;
  @ViewChild('recipientChooseTheirOwnGift')
  recipientChooseTheirOwnGift: MatRadioButton;
  @ViewChild('notSure') notSure: MatRadioButton;

  categories: Page<Category>;
  date: Date = new Date();
  currencies: Currency[] = [];

  public get campaignContent() {
    return this._configurationService?.campaignContent || {};
  }

  getQue(indx: number) {
    if (this.campaignContent?.questionsAboutTheGift?.length) {
      return this.campaignContent?.questionsAboutTheGift[indx].que;
    } else {
      return '';
    }
  }

  count: any;
  countMessageLength(val: string) {
    if (!val) this.count = 0;
    if (val) {
      const length = val.length;
      this.count = length;
    }
  }
  pageType: boolean = false;
  constructor(
    private _categoryService: CategoriesService,
    public _appService: AppService,
    private _campaignService: CampaignService,
    private _router: Router,
    private _giftSuggestionService: GiftSuggestionService,
    private _currenciesService: CurrenciesService,
    private _configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute
  ) {
    if (!this._appService.user?.id) {
      this.notLoginPopup();
    }
    _configurationService.fetch();
    this.categories = new Page({
      api: _categoryService.categories,
      properties: new Category(),
      serverPaging: false,
    });

    this._currenciesService.fetchList().subscribe((d) => {
      this.currencies = [
        ...d.filter((i) => i.code?.toLowerCase()?.includes('gbp')),
      ];
    });
  }

  ngAfterViewInit() {
    this.form.get('campaignCurrency').valueChanges.subscribe((d) => {
      if (d && !this.currencies.find((i) => i.code === d)) {
        this.form.get('campaignCurrency').setErrors({ invalid: 'invalid' });
      }
    });
  }

  searchCurrency(v) {
    this.currencies = this._currenciesService.search(v);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.categories.items.length) {
      const res = await this.categories.fetch();
      res.items.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (changes['campaign'] && this.campaign['giftDecisionType']) {
      setTimeout(() => {
        this.form
          .get('giftDecisionType')
          .setValue(this.campaign['giftDecisionType']);
        this[this.campaign['giftDecisionType']].checked = true;
        this[this.campaign['giftDecisionType']]._markForCheck();
      }, 1000);
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['p'] === 'edit') {
        this.pageType = true;
        this.form.get('receipientName')?.disable();
        this.form.get('message')?.disable();
        this.form.get('campaignCurrency')?.disable();
        this.form.get('addStoryFromContributors')?.disable();
        this.form.get('categoryId')?.disable();
        this.form.get('purposeOfGift')?.disable();
      } else {
        this.form.get('receipientName')?.enable();
        this.form.get('message')?.enable();
        this.form.get('campaignCurrency')?.enable();
        this.form.get('addStoryFromContributors')?.enable();
        this.form.get('categoryId')?.enable();
        this.form.get('purposeOfGift')?.enable();
      }
    });
  }

  get giftIdeas(): FormArray {
    return this.form.get('giftSuggestions') as FormArray;
  }

  singleGiftSuggestion(index): FormGroup {
    return this.giftIdeas.controls[index] as FormGroup;
  }

  addGiftIdea(value: any) {
    if (!value) return;
    if (this.campaign && this.campaign.id) {
      this.giftIdeas.push(
        new FormGroup({
          id: new FormControl(''),
          url: new FormControl(value),
          campaignId: new FormControl(this.campaign.id),
          voteCount: new FormControl(0),
          loading: new FormControl(true),
        })
      );
      this.createGiftIdea(this.singleGiftSuggestion(this.giftIdeas.length - 1));
    } else {
      this.giftIdeas.push(new FormControl(value));
    }
  }

  createGiftIdea(form: FormGroup) {
    this._giftSuggestionService.giftSuggestion
      .create(form.value)
      .then((d) => {
        form.get('id').setValue(d.id);
        form.get('loading').setValue(false);
      })
      .catch((err) => {
        form.get('loading').setValue(false);
        this._appService.snackBarOpen(err);
      });
  }

  deleteGiftIdea(i) {
    if (this.campaign && this.campaign.id) {
      let form: FormGroup = this.singleGiftSuggestion(i);
      form.get('loading').setValue(true);
      this._giftSuggestionService.giftSuggestion
        .remove(form.get('id').value)
        .then((d) => {
          if (
            this.campaign.giftSuggestions &&
            this.campaign.giftSuggestions.length
          )
            this.campaign.giftSuggestions.splice(i, 1);
          this.campaignChange.emit(this.campaign);
          this.giftIdeas.removeAt(i);
        })
        .catch((err) => {
          form.get('loading').setValue(false);
          this._appService.snackBarOpen(err);
        });
    } else {
      this.giftIdeas.removeAt(i);
    }
  }

  get otherCategory(): number {
    let c = this.categories.items.find((i) =>
      i.name.toLowerCase().includes('other')
    );
    if (c) {
      return c.id;
    } else {
      return 0;
    }
  }

  changeImage(event) {
    let dialog = this._appService.matDialog.open(ImageCropperComponent, {
      data: { event },
      disableClose: true,
      minWidth: '500px',
      maxWidth: '100%',
      maxHeight: '100%',
    });

    dialog.afterClosed().subscribe((file: File) => {
      if (file) {
        this.uploadFile(file);
      }
    });
  }

  uploadFile(file: File) {
    let dialog = this._appService.matDialog.open(FileUploaderComponent, {
      data: [file],
      disableClose: true,
      minWidth: '400px',
      maxWidth: '100%',
      maxHeight: '100%',
    });

    dialog.afterClosed().subscribe((urls: string[]) => {
      if (urls.length && urls[0].length) {
        this.form.get('receipientImgUrl').setValue(urls[0]);
      }
    });
  }

  get buttonDisable(): boolean {
    let disable = false;
    Object.keys(this.form.controls).forEach((k) => {
      if (this.giftDecisionTypeOption.includes(k)) {
        if (this.form.get(k).invalid) disable = true;
      }
    });

    return disable;
  }

  markAllAsTouched() {
    Object.keys(this.form.controls).forEach((k) => {
      if (this.giftDecisionTypeOption.includes(k)) {
        this.form.get(k).markAsTouched();
      }
    });
  }

  create() {
    if(this.form.get('giftDecisionType').invalid){
      this.form.get('giftDecisionType').setValue('notSure')
    }
    if (this.buttonDisable) {
      this._appService.snackBarOpen('Please answer all required questions.');
      return this.markAllAsTouched();
    }
    let body: Campaign = { ...this.form.value };
    Object.keys(body).forEach((k) => {

      if (body[k] == '') delete body[k];
    });
    body.addStoryFromContributors = `${
      body.addStoryFromContributors || 'false'
    }` as any;
    this.isLoadingChange.emit(true);
    this._campaignService.campaigns
      .create(body)
      .then((d) => {
        this.idChange.emit(
          d.inviteLink.split('/')[d.inviteLink.split('/').length - 1]
        );
        this.campaignChange.emit(d);
        this._appService.snackBarOpen('campaign successfully created');
        this.next.emit(1);
        this.isLoadingChange.emit(false);
      })
      .catch((err) => {
        this.isLoadingChange.emit(false);
        this._appService.snackBarOpen(err);
      });
  }

  update() {
    // return this.next.emit(1);
    let body: Campaign = { ...this.form.value };

    Object.keys(this.form.controls).forEach(
      (k) => (body[k] = this.form.controls[k].value)
    );
    delete body.giftSuggestions;
    this.isLoadingChange.emit(true);
    this._campaignService.campaigns
      .update(this.campaign.id, body)
      .then((d) => {
        this.isLoadingChange.emit(false);
        this.idChange.emit(
          d.inviteLink.split('/')[d.inviteLink.split('/').length - 1]
        );
        this.campaignChange.emit(d);
        this.next.emit(1);
        this._appService.snackBarOpen('campaign successfully Updated');
      })
      .catch((err) => {
        this.isLoadingChange.emit(false);
        this._appService.snackBarOpen(err);
      });
  }

  minDate(k) {
    if (this.isLoading) {
      return '';
    }
    if (this.id && this.campaign[k]) {
      return new Date();
      // return new Date(this.campaign[k]);
    } else {
      return new Date();
    }
  }

  get giftDecisionTypeOption() {
    let common = [
      'receipientName',
      'maxContributionAmountInTotal',
      'categoryId',
      'purposeOfGift',
      'message',
      'collectionDeadline',
      'sendEmailReminders',
      'addStoryFromContributors',
      'giftDecisionType',
      'campaignCurrency',
    ];
    switch (this.form.get('giftDecisionType').value) {
      case 'decided':
        return ['giftLink', 'giftName', ...common];
      case 'suggestionPoll':
        return ['giftSuggestions', ...common];
      case 'suggestionFromContributors':
        // "receipientGender", "receipientAge", 'particularInterests',
        return [...common];
      case 'recipientChooseTheirOwnGift':
        return ['recipientChooseTheirOwnGift', ...common];
      case 'notSure':
        return ['notSure', ...common];
      default:
        return common;
    }
  }

  saveAndContinue(viewOnly: boolean) {
    this._appService.matDialog.open(CreateCatalogueProductAndProceedComponent, {
      maxHeight: '100vh',
      maxWidth: '100vw',
      width: '90vw',
      height: '90vh',
      data: {
        campaign: {
          ...this.campaign,
          ...this.form.value,
        },
        viewOnly,
      },
    });
  }

  notLoginPopup() {
    this._appService.matDialog.open(NotLoginPopupComponent, {
      width: '450px',
      height: '350px',
      disableClose: true,
    });
  }
}
