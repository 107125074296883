import { giftSuggestion } from './gift-suggestion.model';
import { InviteEmail } from './invite-email.model';
import { User } from './user.model';
import { Category } from './category.model';
import { ContributorGiftIdea } from './contributor-Gift-Idea.model';
import { Contribution } from './contribution';
import { Prodigi } from './prodigiProduct.model';

export class Campaign {
  isAutoEmailToRecipient?: boolean;
  sendToRecipientEmail?: boolean;
  isDonated?: boolean;
  productId?: number | number[];
  giftCardList?: any;
  id?: number;
  cardDimensions?: number;
  receipientName?: string;
  receipientImgUrl?: string;
  contributorGiftIdeas?: ContributorGiftIdea[] = [];
  message?: string;
  collectionDeadline?: string;
  campaignCurrency?: string;
  collectedAmount?: number;
  sendEmailReminders?: boolean;
  giftName?: string;
  addStoryFromContributors?: boolean;
  giftDecisionType?:
    | 'decided'
    | 'suggestionPoll'
    | 'suggestionFromContributors'
    | 'recipientChooseTheirOwnGift'
    | 'notSure';
  giftLink?: string; //if giftDecisionType = decided;
  maxContributionAmountInTotal?: number;
  giftSuggestions?: giftSuggestion[] = []; //if giftDecisionType = suggestionPoll
  giftPollExpiryDate?: string; //if giftDecisionType = suggestionPoll  send giftPollExpiryDate Too
  purposeOfGift?: string; //if giftDecisionType = suggestionFromContributors and if selected other in category then send it and categoryId else send only categoryId
  categoryId?: number;
  category?: Category = {};
  finalTemplate?: string;
  createdAt?: string;
  invitedEmails?: InviteEmail[] = [];
  receipientGender?: 'male' | 'female' | 'other';
  receipientAge?: number;
  particularInterests?: string;
  minimumContributionAmountPerPerson?: number;
  purchaseCard?: boolean;
  giftAmount?: number | number[];
  redeemOrDonate?: 'redeem' | 'donate';
  receipientEmail?: string;
  selectedTemplateUrl?: string;
  accountHolderName?: string;
  accountNumber?: string;
  country?: string;
  routingNumber?: string;
  bankName?: string;
  user?: User = {};
  status?: 'active' | 'dateAchieved' | 'closed';
  uniqueCode?: string;
  contributions?: Contribution[] = [];
  inviteLink?: string;
  remainingAmount?: any;
  isAmountProcessed?: boolean;
  cardRecipientEmail?: string;
  charityId?: number;
  cardEmailTime?: string;
  cardSendEmailTime?: string;
  automaticCardEmail?: boolean;
  userAmount?: number;
  charityAmount?: number;
  cardAmount?: number;
  decidedGift?: boolean;
  wantToDonate?: boolean;
  charityAmountType?: 'autoFilled' | 'other';
  comment?: string;
  stripeCharges?: number = 0;
  tremendousOrderId?: string;
  tremendousRewardId?: string;
  tremendousOrderAmount?: number;
  prodigiDetail?: Prodigi;
  cardType?: 'prodigi' | 'digital';
  payoutType?: 'account' | 'catalogue';
  templateType?: 'uploaded' | 'template' | 'freeCard';
}
