<div class="container-fluid">
  <h3 class="headtitle">
    {{ "CREATE THE Collectagift POT" | uppercase }}
  </h3>
  <form [formGroup]="form" autocapitalize="off">
    <mat-card class="mat-card p-4">
      <ol class="pl-3">
        <li class="col-lg-6 col-md-12 col-12 form-group">
          <label for="name" class="info">
            {{ getQue(0) }}
            <mat-icon tooltip="Please enter name of the recipient"
              >info</mat-icon
            >
          </label>
          <input
            class="form-control"
            formControlName="receipientName"
            type="text"
            id="name"
            maxlength="50"
            placeholder="Name"
            maxlength="100"
          />
        </li>

        <li>
          <label class="info">
            {{ getQue(1) }}
            <!-- {{ form.get("receipientName")?.value || "Recipient" }}? -->
            <mat-icon tooltip="This photo will appear on the contribution link"
              >info</mat-icon
            >&nbsp;
            <i><small>( optional )</small></i>
          </label>
          <div class="img">
            <input
              type="file"
              class="d-none"
              (click)="file.value = null"
              #file
              (change)="changeImage($event)"
            />
            <img
              (click)="file.click()"
              [src]="form.get('receipientImgUrl').value"
              onerror="this.src='assets/images/team-man-placeholder.jpg'"
              alt=""
            />
            <mat-icon matRipple (click)="file.click()">edit</mat-icon>
          </div>
        </li>

        <li class="col-12 form-group">
          <label for="invite" class="info">
            {{ getQue(2) }}
            <mat-icon
              tooltip="This will let the Contributors know a little bit about why you would like them to contribute."
            >
              info
            </mat-icon>
          </label>
          <textarea
            formControlName="message"
            class="form-control w-50"
            id="invite"
            rows="4"
            maxlength="250"
            (input)="countMessageLength(form.get('message')?.value)"
            placeholder="For example: As you may know, Sarah is moving on to a new job, so I thought it would be nice to group together and buy her a gift."
          ></textarea>
          <div class="pt-2 w-50 text-right">{{ this.count || 0 }}/250</div>
        </li>

        <li class="col-lg-6 col-md-12 col-12 form-group">
          <label class="info">
            {{ getQue(3) }}

            <mat-icon
              tooltip="This lets contributors know how long they have before the contribution link expires and the CollectaGift Pot closes to new contributions.  The link expires on 11.59 pm on the selected date."
            >
              info</mat-icon
            >
          </label>
          <br />
          <app-date-input
            [minDate]="minDate('collectionDeadline')"
            [control]="form.get('collectionDeadline')"
          >
          </app-date-input>
        </li>

        <li class="col-12 form-group">
          <label for="currency" class="info">
            {{ getQue(4) }}

            <mat-icon
              tooltip="Currently this is only available in GBP, but more currencies are coming soon"
            >
              info</mat-icon
            >
          </label>
          <input
            maxlength="100"
            class="form-control w-50"
            type="text"
            placeholder="Please Select your currency"
            (input)="searchCurrency($event.target.value)"
            [matAutocomplete]="currenciesAutocomplete"
            formControlName="campaignCurrency"
          />
          <mat-autocomplete #currenciesAutocomplete>
            <mat-option [value]="item.code" *ngFor="let item of currencies">{{
              item.name
            }}</mat-option>
          </mat-autocomplete>
        </li>

        <!-- <li class="col-6 form-group">
          <label >Would you like to send email reminder?</label> <br>
          <mat-radio-group class="orange" formControlName="sendEmailReminders">
            <mat-radio-button class="mr-5" [value]="true"> Yes</mat-radio-button>
            <mat-radio-button [value]="false"> No</mat-radio-button>
          </mat-radio-group>
        </li> -->

        <li class="col-lg-8 col-md-12 col-12 form-group">
          <label class="info">
            {{ getQue(5) }}

            <!-- {{ form.get("receipientName")?.value || "Recipient" }}? -->
            <mat-icon
              tooltip="This will prompt the Contributors to share a short story or memory about {{
                form.get('receipientName')?.value || 'Recipient'
              }} and is particularly useful if you would like to incorporate them into a leaving speech or wedding speech etc"
            >
              info</mat-icon
            >
          </label>
          <br />
          <mat-radio-group
            class="orange"
            formControlName="addStoryFromContributors"
          >
            <mat-radio-button class="mr-5" [value]="true">
              Yes</mat-radio-button
            >
            <mat-radio-button [value]="false"> No</mat-radio-button>
          </mat-radio-group>
        </li>

        <li class="mt-3">
          <label class="mb-3">
            {{ getQue(6) }}
          </label>
          <br />
          <div class="form-group icon right col-lg-6 p-0">
            <!-- <mat-select placeholder="Purpose of this gift" class="form-control" formControlName="categoryId">
              <mat-option *ngFor="let item of categories?.items" [value]="item?.id">{{item?.name}}</mat-option>
            </mat-select>
            <img src="assets/icons/Profile Down.png" alt=""> -->

            <select
              class="form-control"
              placeholder="Purpose of this gift"
              id="exampleFormControlSelect1"
              formControlName="categoryId"
            >
              <option [value]="''">Select category</option>
              <option *ngFor="let item of categories?.items" [value]="item?.id">
                {{ item?.name }}
              </option>
            </select>
            <!-- <img src="assets/icons/Profile Down.png" alt=""> -->
          </div>
          <!-- <mat-radio-group class="orange" formControlName="categoryId"
            (change)="resetControl('purposeOfGift', ($event.value === otherCategory) ? true : false);">
            <div class="row">
              <mat-radio-button *ngFor="let item of categories?.items" class="col-3 mb-3" [value]="item?.id">
                {{item?.name}}</mat-radio-button>
            </div>
          </mat-radio-group> -->
          <div
            class="form-group"
            *ngIf="+form.get('categoryId').value === otherCategory"
          >
            <input
              type="text"
              maxlength="100"
              placeholder="Purpose of this gift"
              class="form-control col-6"
              formControlName="purposeOfGift"
            />
          </div>
        </li>

        <li class="orange">
          <label class="mb-0 info">
            {{ getQue(7) }}
            <mat-icon
              tooltip="This will allow you to let the Contributors know what gift you intend to buy or for them to suggest or vote on gift ideas etc.  Don't worry, this isn't final and you can always change your mind when you come to redeem the gift."
            >
              info</mat-icon
            >
            <small class="pl-2"> <i> ( optional ) </i> </small> </label
          ><br />
          <mat-error *ngIf="form.get('giftDecisionType')?.invalid">
            Please choose one of the following options.If you need any gift
            inspiration, please view our gift catalogue
            <span
              (click)="
                $event.stopPropagation();
                $event.preventDefault();
                saveAndContinue(true)
              "
              style="text-decoration: underline; cursor: pointer"
              >here</span
            >
          </mat-error>

          <div class="mt-4">
            <!-- (click)="campaign?.id? null: form.get('giftDecisionType').setValue('decided')"> -->
            <mat-radio-button
              #decided
              [disabled]="pageType"
              [value]="'decided'"
              (click)="form.get('giftDecisionType').setValue('decided')"
            >
              <span class="info">
                I already know what gift I would like to buy
                <mat-icon
                  tooltip="Please choose a gift voucher from our Gift Catalogue.  You can either gift the voucher itself for the Recipient to choose from selected provider OR you can purchase a voucher from a merchant in our Gift Catalogue and use the voucher to buy the Recipient a gift from that merchant.  You can either spend the full amount on a single voucher or split it between 4 different merchants."
                >
                  info</mat-icon
                >
              </span>
            </mat-radio-button>

            <div
              class="ml-3 mt-3"
              *ngIf="form.get('giftDecisionType').value === 'decided'"
            >
              <!-- *ngIf="!campaign?.id" -->
              <input
                type="text"
                maxlength="100"
                class="form-control col-6"
                formControlName="giftName"
                placeholder="Please provide a brief description of the gift"
              />
              <!-- <input
                type="text"
                class="form-control col-6"
                [value]="form.get('giftName')?.value"
                *ngIf="campaign?.id"
                disabled
              /> -->
            </div>

            <div
              class="ml-3 mt-3"
              *ngIf="form.get('giftDecisionType').value === 'decided'"
            >
              <div class="d-flex align-items-center">
                <!-- *ngIf="!campaign?.id" -->
                <input
                  type="text"
                  maxlength="100"
                  class="form-control col-6"
                  formControlName="giftLink"
                  placeholder="Link"
                />
                <i *ngIf="!campaign?.id"
                  ><small class="pl-2">( optional )</small></i
                >
              </div>
              <!-- <input
                type="text"
                class="form-control col-6"
                [value]="form.get('giftLink')?.value"
                *ngIf="campaign?.id"
                disabled
              /> -->
            </div>
          </div>

          <div class="mt-4">
            <!-- [disabled]="campaign?.id" -->
            <!-- (click)="campaign?.id? null: form.get('giftDecisionType').setValue('suggestionPoll')"> -->
            <mat-radio-button
              [disabled]="pageType"
              [value]="'suggestionPoll'"
              #suggestionPoll
              (click)="form.get('giftDecisionType').setValue('suggestionPoll')"
            >
              <span class="info">
                I have a short list of gift ideas for contributors to vote on
                their preferred gift
                <mat-icon
                  tooltip="By selecting this, Contributors will be asked to vote for their preferred gift idea. You can add in multiple ideas by entering one idea and then clicking Add for the next idea.  Please choose ideas from our Gift Catalogue.  You can either gift the voucher itself to the Recipient or you can buy a voucher to then buy a gift for the Recipient using that voucher. "
                >
                  info</mat-icon
                >
              </span>
            </mat-radio-button>

            <div
              class="ml-3 mt-3"
              *ngIf="form.get('giftDecisionType')?.value === 'suggestionPoll'"
            >
              <div class="d-flex mt-3 align-items-center">
                <!-- [disabled]="campaign?.id || giftIdeas.length === 3" -->
                <input
                  type="text"
                  [class.ng-invalid]="giftIdeas?.invalid"
                  [class.ng-touched]="giftIdeas?.touched"
                  class="col-6 form-control d-inline-block"
                  [maxLength]="150"
                  (change)="addGiftIdea(giftIdea?.value); giftIdea.value = ''"
                  placeholder="gift Idea"
                  #giftIdea
                />
                <button
                  class="ml-3"
                  type="button"
                  ButtonUi
                  color="c1"
                  (click)="addGiftIdea(giftIdea?.value); giftIdea.value = ''"
                >
                  Add
                </button>
                <!-- <div class="ml-auto">
                  <label>Gift Poll Expiry Date</label> <br>
                  <app-date-input [minDate]="minDate('giftPollExpiryDate')" [control]="form.get('giftPollExpiryDate')">
                  </app-date-input>
                </div> -->
              </div>
              <div formArrayName="giftSuggestions" *ngIf="!campaign?.id">
                <div
                  class="d-flex align-items-center"
                  *ngFor="let item of giftIdeas?.controls; let i = index"
                >
                  <button
                    mat-icon-button
                    type="button"
                    (click)="deleteGiftIdea(i)"
                  >
                    <img src="assets/images/delete.png" alt="" />
                  </button>
                  <span class="text-muted">{{ item?.value }}</span>
                </div>
              </div>
              <div
                formArrayName="giftSuggestions"
                class="gift-ideas"
                *ngIf="campaign?.id"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="let item of giftIdeas?.controls; let i = index"
                >
                  <button
                    mat-icon-button
                    type="button"
                    [disabled]="item.get('loading')?.value"
                    (click)="deleteGiftIdea(i)"
                  >
                    <img src="assets/images/delete.png" alt="" />
                  </button>
                  <span class="text-muted">{{
                    item?.get("url")?.value || "N/a"
                  }}</span>
                  <mat-error
                    *ngIf="
                      !item.get('loading')?.value && !item.get('id')?.value
                    "
                  >
                    <a (click)="createGiftIdea(item)">Retry</a>
                  </mat-error>
                  <mat-spinner
                    *ngIf="item.get('loading')?.value"
                    class="ml-3 orange"
                    [diameter]="20"
                    [strokeWidth]="1.5"
                  ></mat-spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <!-- [disabled]="campaign?.id" -->
            <!-- (click)="campaign?.id? null: form.get('giftDecisionType').setValue('suggestionFromContributors')"> -->
            <mat-radio-button
              [disabled]="pageType"
              #suggestionFromContributors
              [value]="'suggestionFromContributors'"
              (click)="
                form
                  .get('giftDecisionType')
                  .setValue('suggestionFromContributors')
              "
            >
              <span class="info">
                <!-- Would you like contributors to suggest gift ideas? -->
                I would like contributors to suggest gift ideas
                <mat-icon
                  tooltip="Select this if you are unsure what gift you would like to buy.  This will allow the Contributors to suggest gift ideas from our Gift Catalogue when they make their contributon.  They will either be able to suggest which Gift Voucher to choose or a gift that can be bought using a voucher from our Gift Catalogue."
                >
                  info</mat-icon
                >
              </span>
            </mat-radio-button>
            <!-- </mat-radio-group> -->

            <!-- <div *ngIf="form.get('giftDecisionType')?.value == 'suggestionFromContributors'">
              <ol type="i" class="pl-3">

                <li>
                  <label class="mb-3">Is this for male/female?</label> <br>
                  <mat-radio-group class="orange" formControlName="receipientGender">
                    <mat-radio-button class="mb-3" value="male">Male</mat-radio-button>
                    <mat-radio-button value="female" class="ml-5">Female</mat-radio-button>
                  </mat-radio-group>
                </li>
                <li>
                  <label class="mb-3">What is their age range?</label> <br>
                  <div class="form-group icon col-3 p-0">
                    <mat-select readonly class="underline skyblue" placeholder="Age range" data-toggle="dropdown"
                      formControlName="receipientAge">
                      <mat-option [value]="o.value" *ngFor="let o of ageRangeOptions">{{o.label}}</mat-option>
                    </mat-select>
                    <img src="assets/images/blue down.png" alt="" style="right: 2px;">
                  </div>
                </li>
                <li class="mb-3">
                  <label class="mb-3">Do they have any particular interests?</label>
                  <input type="text" class="form-control col-6" formControlName="particularInterests"
                    placeholder="Enter Here...">
                </li>
              </ol>
            </div> -->
          </div>
          <div class="mt-4">
            <!-- [disabled]="campaign?.id" -->
            <!-- (click)="campaign?.id? null: form.get('giftDecisionType').setValue('suggestionFromContributors')"> -->
            <mat-radio-button
              [disabled]="pageType"
              #recipientChooseTheirOwnGift
              [value]="'recipientChooseTheirOwnGift'"
              (click)="
                form
                  .get('giftDecisionType')
                  .setValue('recipientChooseTheirOwnGift')
              "
            >
              <span class="info">
                I would like to let the Gift Recipient choose their Own Gift
                <mat-icon
                  tooltip="This will generate a link for recipient to log in and choose their own gift."
                >
                  info</mat-icon
                >
              </span>
            </mat-radio-button>
          </div>
          <div class="mt-4">
            <mat-radio-button
              [disabled]="pageType"
              #notSure
              [value]="'notSure'"
              (click)="form.get('giftDecisionType').setValue('notSure')"
            >
              <span class="info"> I’m not sure yet </span>
            </mat-radio-button>
          </div>
          <!-- </mat-radio-group> -->
        </li>
        <li class="mt-3">
          <label class="info m-0">
            {{ getQue(8) }}
            <mat-icon
              tooltip="This is useful if you know what gift you would like to buy so you can target the cost of this gift. It's always useful to set the target a little higher in case the target isn't reached and also to ensure there are funds available to purchase a group e-card if you would like."
            >
              info</mat-icon
            >
            &nbsp;
            <i><small>( optional )</small></i>
          </label>
          <br />
          <div class="form-group icon d-inline-block w-50 left">
            <input
              type="text"
              maxlength="10"
              class="form-control"
              formControlName="maxContributionAmountInTotal"
              placeholder="Enter Amount"
            />
            <span class="text">{{
              _appService.getCurrencySymbol(
                form.get("currency")?.value || "GBP"
              )
            }}</span>
          </div>
          <!-- <div class="text-danger">
            *Fees are £4 for CollectaGift pot up to £500 or £6 for CollectaGift pot up to £1000 and <br>
            Buy Digital card for £2
          </div> -->
        </li>
        <!-- <li class="col-8 form-group mt-3">
          <label class="info">
            Would you like to set-up automated reminders?
            <mat-icon
              tooltip="This will send you periodic reminders ahead of the expiry date, so you can remind invited contributors to contribute, if they have not already done so.">
              info</mat-icon>
          </label> <br>
          <mat-radio-group class="orange" formControlName="sendDeadlineReminder">
            <mat-radio-button class="mr-5" [value]="true"> Yes</mat-radio-button>
            <mat-radio-button [value]="false"> No</mat-radio-button>
          </mat-radio-group>
        </li> -->
      </ol>
    </mat-card>
    <div class="actions">
      <button
        *ngIf="id"
        ButtonUi
        [disabled]="isLoading"
        color="c1"
        size="lg"
        (click)="update()"
        [loading]="isLoading"
      >
        NEXT
      </button>
      <button
        *ngIf="!id"
        ButtonUi
        [disabled]="isLoading"
        color="c1"
        size="lg"
        (click)="create()"
        [loading]="isLoading"
      >
        NEXT
      </button>
    </div>
  </form>
</div>
